import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Caption = makeShortcode("Caption");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "color"
    }}>{`Color`}</h2>
    <p>{`All steps that have been completed are indicated by an outlined circle with a checkmark. The current step the user is on is indicated by a filled circle. Steps the user has not encountered yet, or future steps, are indicated by an outlined circle.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Color token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--progress-step--complete svg`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`fill`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$interactive-04`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--progress-step--incomplete svg`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`fill`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$interactive-04`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--progress-step--current`}</inlineCode>{` `}<br />{` `}<inlineCode parentName="td">{`.bx--progress-line`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$interactive-04`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--progress-step--incomplete`}</inlineCode>{` `}<br />{` `}<inlineCode parentName="td">{`.bx--progress-line`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$ui-03`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--progress-label`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$text-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--progress-optional`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$text-02`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "interactive-states"
    }}>{`Interactive states`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Color token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--progress-step:focus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$focus`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--progress-label:hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$link-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--progress__warning`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`fill`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$support-01`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <div className="image--fixed">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "46.19565217391305%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsSAAALEgHS3X78AAAA6UlEQVQoz2NgwAP+//+PIXbp0iUGkgHIIJhhQJoJhO89esFy4cpNZpDY5et3STMwPzeLoaayBMwuKSkSLCvMlLp8cgfv/59P2EFi319fJ9GJbNVAYgqUI8LIIH2MlUHhPzOD/H8msJD8f9LMY0r9zyAQdhjM5kr9oiRgVSvN7budj6XgPxtIjD31D2kGCnYADcx4B2bzl/7nEEu8xirW8Z9ZrBziQrEKEl3Ipp/PIOwyAczmNCriE7JvlRB26hHjy/nLAbYk7gbpMS2KFAKSDAys0gwMLGDDUl8xkA3E0fj8GR8hPjiI28sALMNLgH8W7DQAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Examples of current, completed, and future steps for progress indicator",
          "title": "Examples of current, completed, and future steps for progress indicator",
          "src": "/static/78e285de1362d8267ec7796e2f3c4158/fb070/progress-indicator-style-1.png",
          "srcSet": ["/static/78e285de1362d8267ec7796e2f3c4158/d6747/progress-indicator-style-1.png 288w", "/static/78e285de1362d8267ec7796e2f3c4158/09548/progress-indicator-style-1.png 576w", "/static/78e285de1362d8267ec7796e2f3c4158/fb070/progress-indicator-style-1.png 1152w", "/static/78e285de1362d8267ec7796e2f3c4158/c3e47/progress-indicator-style-1.png 1472w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </div>
    <Caption mdxType="Caption">
  Examples of current, completed, and future steps for progress indicator
    </Caption>
    <h2 {...{
      "id": "typography"
    }}>{`Typography`}</h2>
    <p>{`Labels should be one to two words only, with a limit of 16 characters total per label. All labels should be set in sentence case.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font-size (px/rem)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font-weight`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--progress-label`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14 / 0.875`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular / 400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$body-short-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--progress-optional`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14 / 0.875`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular / 400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$label-01`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "structure"
    }}>{`Structure`}</h2>
    <p>{`The checkmark icon can be found in the `}<a parentName="p" {...{
        "href": "/style/iconography/library"
      }}>{`iconography`}</a>{` library.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`px / rem`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Spacing token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--progress-step`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`min-width`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`128 / 8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--progress-step svg`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`height, width`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--progress-step svg`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`margin-top, margin-right`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--progress-label`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`margin-top`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <div className="image--fixed">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "31.38586956521739%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsSAAALEgHS3X78AAABTElEQVQY02M4oFPEwLDsP8N/BgYGz4CLDAz/QSwGMD2rcw3DtlWrGfa9fsIwfe9ZhrmdSxiu3jjHsOj2XYapU7cx7FixlGHn48cME+7fB2uZ/uABA1bwX0qciYFE0PPwIYRxQKeQgSEP4sIwn+PSSfYL1UtNZ8tWtR5TmzVjh8SO5av5Drx8LDTrwHnZpdM2Cd5+f4970fOnktNn75LYtWoF3+7Hj0Um3b/PDzJrJsiF59XTGRgmgQ1kjvM9pmVg1SkNkow6ck4rMKpX2ZKBgX36/1+82XO2ayYYR2ms8I0XmLpgm35GQLeKMAMDW/+bd4IVD+6LgfRUP3qExw+2UiwYQXGMgWENQwjW4GiAefm8ehoDwzyIl72CbzCamrQzMOgagOW2C69iUNE0ZVj1/w9DzpzTKAaoRq9msOPhZpj85w9D+b27YLFqoKEARlSDq+1BI6AAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Structure and spacing for progress indicator",
          "title": "Structure and spacing for progress indicator",
          "src": "/static/91a3acf1fcae77afbd7cc0049870defd/fb070/progress-indicator-style-2.png",
          "srcSet": ["/static/91a3acf1fcae77afbd7cc0049870defd/d6747/progress-indicator-style-2.png 288w", "/static/91a3acf1fcae77afbd7cc0049870defd/09548/progress-indicator-style-2.png 576w", "/static/91a3acf1fcae77afbd7cc0049870defd/fb070/progress-indicator-style-2.png 1152w", "/static/91a3acf1fcae77afbd7cc0049870defd/c3e47/progress-indicator-style-2.png 1472w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </div>
    <Caption mdxType="Caption">
  Structure and spacing measurements for progress indicator | px / rem
    </Caption>
    <h3 {...{
      "id": "recommended"
    }}>{`Recommended`}</h3>
    <p>{`The following specs are not built into the progress indicator component but are recommended by design as the proper amount between progress indicator elements.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`px / rem`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Spacing token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--progress`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`margin`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <div className="image--fixed">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "32.065217391304344%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsSAAALEgHS3X78AAABV0lEQVQY02NgAAKDnXsYKAVpz55CGHJLZ4NpwdmzWMy2zxbyPrlFoOLlea4p/9+xl///z14ExOlPnnAUA2kQP/PFC46cjx84qv//Zyv49Ys97ekT9viXL8BmJIEMTb9+F8zJeXBHIvbE45iAjVccoo48s00//cSm+cJ5x7Zr1+z6njy2ar161b758mX7nkcPrbvu3rVpPHfOqfP6dau2J48DSu/fkweZUfXwISNDzi0gAQTFTx/JxR55GuMxaalW+oVbyulnrqnELVumn75jh1bDmzfyyevX68ZMnmxXdvGCatGliyqxS5YYpW/fplj99Klzwb17aiAzSh4+YGQoefIA7MLcu3c5Wz4/UW/98Ump9uUz1dpXz1Xrv3xWrnn3VqXi6RPV2rdvVWqeP1erePJErerlS5WGr1+Ua96+US5/8kS74OEDHrCjHj2ChGPB/fsURwrMMACxRquVe9Kk9QAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Structure and spacing for progress indicator",
          "title": "Structure and spacing for progress indicator",
          "src": "/static/601b3679ba089f7f64734cd6a447faae/fb070/progress-indicator-style-3.png",
          "srcSet": ["/static/601b3679ba089f7f64734cd6a447faae/d6747/progress-indicator-style-3.png 288w", "/static/601b3679ba089f7f64734cd6a447faae/09548/progress-indicator-style-3.png 576w", "/static/601b3679ba089f7f64734cd6a447faae/fb070/progress-indicator-style-3.png 1152w", "/static/601b3679ba089f7f64734cd6a447faae/c3e47/progress-indicator-style-3.png 1472w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </div>
    <Caption mdxType="Caption">
  Recommended structure and spacing measurements for progress indicator | px /
  rem
    </Caption>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      